import store from '../store/store';

export function canAccess(path, role){    
   if(role === undefined || path === undefined) return false;
   if(role !== 'NstpCoordinator') return false;
   return true;
}

export function checkNavigationGuards(to, from, next){
   if (to.name !== 'Login'  && !store.state.isAuthenticated) next({ name: 'Login' });  
   else if(to.name == 'Login' && store.state.isAuthenticated && store.state.user.role == 'NstpCoordinator') next({ name: 'Main'});            
   else next(); 
}

