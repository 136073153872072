import Vue from 'vue'
import router from '../src/router/router';
import store from '../src/store/store';
import axios from 'axios';
import { Form } from 'vform';
import App from './App.vue'
import { printOptions } from './common/options.js';
import './common/filters';
import { checkNavigationGuards } from './middlewares/auth';
import VueHtmlToPaper from 'vue-html-to-paper';

Vue.config.productionTip = false;
Vue.use(VueHtmlToPaper, printOptions);
window.axios = axios;
window.Form = Form;
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.chedcaraga.ph/';


router.beforeEach(checkNavigationGuards);

 new Vue({
  el: '#app',
  router,
  store,  
  template: '<App/>',
  components: {
      App
  }
}) 
