import Vue from 'vue';
import moment from 'moment';


Vue.filter('dateTime', function (created){
    return moment(created).format("lll"); // 01/22/2022
});

Vue.filter("standardDate", function (created) {
    return moment(created).format("LL"); //January 2, 2021
});

Vue.filter("shortDate", function (created) {
    return moment(created).format("L"); // 01/22/2022
});

Vue.filter('completeDateTime', function (created){
    return moment(created).format("llll"); // 01/22/2022
});

Vue.filter("yearOnly", function (created) {
    return moment(created).format("YYYY"); // 2021
});
 