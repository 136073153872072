import Vue from 'vue';
import Router from 'vue-router';
import { canAccess } from '../middlewares/auth';
import store from '../store/store';

const NstpClient = () => import('@/views/nstp/NstpClient.vue');
const unauthorizedPage = () => import('@/views/nstp/404.vue');
const Login = () => import('@/views/Login.vue');

Vue.use(Router);

export default new Router({
    mode: 'history', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [
        {
            path: '*',
            name: '404',
            component: unauthorizedPage,
        },
        {
            path: '/unauthorized',
            name: 'Unauthorized',
            component: unauthorizedPage,
        },
        {
            path: '/',
            redirect: '/login'
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
        },
        {
            path: '/main',
            name: 'Main',
            component: NstpClient,
            beforeEnter(to, from, next) {
                canAccess('NstpClient', store.state.user.role) ? next() : next({ name: 'Unauthorized' });
            }
            
        }
    ]
}